import React, { useEffect } from 'react'
// import Testimonials from '../components/Testimonials'
import { PageProps } from 'gatsby'
// import useTestimonialsQuery from '../hooks/useTestimonialsQuery'
import { LayoutProps } from '../components/Layout.context'
import useSaveShopsQuery from '../hooks/useSaveShopsQuery'

const ExternalSourceReparation: React.FC<PageProps> = () => {
  const isBrowser = () => typeof window !== 'undefined'
  const shops = useSaveShopsQuery()

  // const testimonials = useTestimonialsQuery()
  useEffect(() => {
    const params = isBrowser()
      ? new URLSearchParams(window.location.search)
      : new URLSearchParams()

    const shopId = params.get('shopId') ?? ''

    sessionStorage.clear()
    if (shopId !== '') {
      const shop = shops.find((shop) => {
        return shop.shopId?.toLowerCase() === shopId.toLocaleLowerCase()
      })
      if (shop != undefined) {
        sessionStorage.setItem('shopIdStored', shopId)
      }
    }

    // 👇️ redirects to an external URL
    window.location.replace('/reparation')
  }, [])

  return <></>
}

const layoutProps: LayoutProps = {
  title: 'Leader européen de la réparation de smartphones et tablettes',
}

export default Object.assign(ExternalSourceReparation, {
  layoutProps,
})
